export const employmentTypes = [
	{
		value: "full_time",
		label: "Full-Time",
	},
	{
		value: "part_time",
		label: "Part-Time",
	},
	{
		value: "contract",
		label: "Contract",
	},
];

export const locationBenefits = [
	{
		value: "work_from_home_allowed",
		label: "Work from home allowed",
	},
	{
		value: "relocation_assistance",
		label: "Relocation assistance provided",
	},
	{
		value: "visa_sponsorship",
		label: "Visa sponsorship available",
	},
];

export const gender = [
	{
		value: "Male",
		label: "Male",
	},
	{
		value: "Female",
		label: "Female",
	},
	{
		value: "Other",
		label: "Other",
	},
	{
		value: "Non_Binary",
		label: " Non Binary",
	},
	{
		value: "Prefer_Not_To_Say",
		label: "Prefer Not To Say",
	},
];

export const countryList = [
	{ value: "AF", label: "Afghanistan" },
	{ value: "AX", label: "Åland Islands" },
	{ value: "AL", label: "Albania" },
	{ value: "DZ", label: "Algeria" },
	{ value: "AS", label: "American Samoa" },
	{ value: "AD", label: "Andorra" },
	{ value: "AO", label: "Angola" },
	{ value: "AI", label: "Anguilla" },
	{ value: "AQ", label: "Antartica" },
	{ value: "AG", label: "Antigua and Barbuda" },
	{ value: "AR", label: "Argentina" },
	{ value: "AM", label: "Armenia" },
	{ value: "AW", label: "Aruba" },
	{ value: "AU", label: "Autralia" },
	{ value: "AT", label: "Austria" },
	{ value: "AZ", label: "Azerbaijan" },
	{ value: "BS", label: "Bahamas" },
	{ value: "BH", label: "Bahrain" },
	{ value: "BD", label: "Bangladesh" },
	{ value: "BB", label: "Barbados" },
	{ value: "BY", label: "Belarus" },
	{ value: "BE", label: "Belgium" },
	{ value: "BZ", label: "Belize" },
	{ value: "BJ", label: "Benin" },
	{ value: "BM", label: "Bermuda" },
	{ value: "BT", label: "Bhutan" },
	{ value: "BO", label: "Bolivia, Plurinational State of" },
	{ value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
	{ value: "BA", label: "Bosnia and Herzegovina" },
	{ value: "BW", label: "Botswana" },
	{ value: "BV", label: "Bouvet Island" },
	{ value: "BR", label: "Brazil" },
	{ value: "IO", label: "British Indian Ocean Territory" },
	{ value: "BN", label: "Brunei Darussalam" },
	{ value: "BG", label: "Bulgaria" },
	{ value: "BF", label: "Burkina Faso" },
	{ value: "BI", label: "Burundi" },
	{ value: "KH", label: "Cambodia" },
	{ value: "CM", label: "Cameroon" },
	{ value: "CA", label: "Canada" },
	{ value: "CV", label: "Cape Verde" },
	{ value: "KY", label: "Cayman Islands" },
	{ value: "CF", label: "Central African Republic" },
	{ value: "TD", label: "Chad" },
	{ value: "CL", label: "Chile" },
	{ value: "CN", label: "China" },
	{ value: "CX", label: "Christman Island" },
	{ value: "CC", label: "Cocos (Keeling Islands)" },
	{ value: "CO", label: "Colombia" },
	{ value: "KM", label: "Comoros" },
	{ value: "CG", label: "Congo" },
	{ value: "CD", label: "Congo, The Democratic Republic of the" },
	{ value: "CK", label: "Cook Islands" },
	{ value: "CR", label: "Costa Rica" },
	{ value: "CI", label: "Cxf4te D'ivoire" },
	{ value: "HR", label: "Croatia" },
	{ value: "CU", label: "Cuba" },
	{ value: "CW", label: "Curaxeao" },
	{ value: "CY", label: "Cyprus" },
	{ value: "CZ", label: "Czech Republic" },
	{ value: "DK", label: "Denmark" },
	{ value: "DJ", label: "Djibouti" },
	{ value: "DM", label: "Dominica" },
	{ value: "DO", label: "Dominican Republic" },
	{ value: "EC", label: "Ecuador" },
	{ value: "EG", label: "Egypt" },
	{ value: "SV", label: "El Salvador" },
	{ value: "GQ", label: "Equatorial Guinea" },
	{ value: "ER", label: "Eritrea" },
	{ value: "EE", label: "Estonia" },
	{ value: "ET", label: "Ethiopia" },
	{ value: "FK", label: "Falkland Islands (Malvinas)" },
	{ value: "FO", label: "Faroe Islands" },
	{ value: "FJ", label: "Fiji" },
	{ value: "FI", label: "Finland" },
	{ value: "FR", label: "France" },
	{ value: "GF", label: "French Guiana" },
	{ value: "PF", label: "French Polynesia" },
	{ value: "TF", label: "French Southern Territories" },
	{ value: "GA", label: "Gabon" },
	{ value: "GM", label: "Gambia" },
	{ value: "GE", label: "Georgia" },
	{ value: "DE", label: "Germany" },
	{ value: "GH", label: "Ghana" },
	{ value: "GI", label: "Gibraltar" },
	{ value: "GR", label: "Greece" },
	{ value: "GL", label: "Greenland" },
	{ value: "GD", label: "Grenada" },
	{ value: "GP", label: "Guadeloupe" },
	{ value: "GU", label: "Guam" },
	{ value: "GT", label: "Guatemala" },
	{ value: "GG", label: "Guernsey" },
	{ value: "GN", label: "Guinea" },
	{ value: "GW", label: "Guinea-bissau" },
	{ value: "GY", label: "Guyana" },
	{ value: "HT", label: "Haiti" },
	{ value: "HM", label: "Heard Island and McDonald Islands" },
	{ value: "VA", label: "Holy See (Vatican City State)" },
	{ value: "HN", label: "Honduras" },
	{ value: "HK", label: "Hong Kong" },
	{ value: "HU", label: "Hungary" },
	{ value: "IS", label: "Iceland" },
	{ value: "IN", label: "India" },
	{ value: "ID", label: "Indonesia" },
	{ value: "IR", label: "Iran, Islamic Republic of" },
	{ value: "IQ", label: "Iraq" },
	{ value: "IE", label: "Ireland" },
	{ value: "IM", label: "Isle of Man" },
	{ value: "IL", label: "Israel" },
	{ value: "IT", label: "Italy" },
	{ value: "JM", label: "Jamaica" },
	{ value: "JP", label: "Japan" },
	{ value: "JE", label: "Jersey" },
	{ value: "JO", label: "Jordan'" },
	{ value: "KZ", label: "Kazakhstan" },
	{ value: "KE", label: "Kenya" },
	{ value: "KI", label: "Kiribati" },
	{ value: "KP", label: "Korea, Democratic People's Republic of" },
	{ value: "KR", label: "Korea, Republic of" },
	{ value: "KW", label: "Kuwait" },
	{ value: "KG", label: "Kyrgyzstan" },
	{ value: "LA", label: "Lao People's Democratic Republic" },
	{ value: "LV", label: "Latvia" },
	{ value: "LB", label: "Lebanon" },
	{ value: "LS", label: "Lesotho" },
	{ value: "LR", label: "Liberia" },
	{ value: "LY", label: "Libya" },
	{ value: "LI", label: "Liechtenstein" },
	{ value: "LT", label: "Lithuania" },
	{ value: "LU", label: "LuxembourgLuxembourg" },
	{ value: "MO", label: "Macao" },
	{ value: "MK", label: "Macedonia, The Former Yugoslav Republic of" },
	{ value: "MG", label: "Madagascar" },
	{ value: "MW", label: "Malawi" },
	{ value: "MY", label: "Malaysia" },
	{ value: "MV", label: "Maldives" },
	{ value: "ML", label: "Mali" },
	{ value: "MT", label: "Malta" },
	{ value: "MH", label: "Marshall Islands" },
	{ value: "MQ", label: "Martinique" },
	{ value: "MR", label: "Mauritania" },
	{ value: "MU", label: "Mauritius" },
	{ value: "YT", label: "Mayotte" },
	{ value: "MX", label: "Mexico" },
	{ value: "FM", label: "Micronesia, Federated States of" },
	{ value: "MD", label: "Moldova, Republic of" },
	{ value: "MC", label: "Monaco" },
	{ value: "MN", label: "Mongolia" },
	{ value: "ME", label: "Montenegro" },
	{ value: "MS", label: "Montserrat" },
	{ value: "MA", label: "Morocco" },
	{ value: "MZ", label: "Mozambique" },
	{ value: "MM", label: "Myanmar" },
	{ value: "NA", label: "Namibia" },
	{ value: "NR", label: "Nauru" },
	{ value: "NP", label: "Nepal" },
	{ value: "NL", label: "Netherlands" },
	{ value: "NC", label: "New Caledonia" },
	{ value: "NZ", label: "New Zealand" },
	{ value: "NI", label: "Nicaragua" },
	{ value: "NE", label: "Niger" },
	{ value: "NG", label: "Nigeria" },
	{ value: "NU", label: "Niue" },
	{ value: "NF", label: "Norfolk Island" },
	{ value: "MP", label: "Northern Mariana Islands" },
	{ value: "NO", label: "Norway" },
	{ value: "OM", label: "Oman" },
	{ value: "PK", label: "Pakistan" },
	{ value: "PW", label: "Palau" },
	{ value: "PS", label: "Palestinian Territory, Occupied" },
	{ value: "PA", label: "Panama" },
	{ value: "PG", label: "Papua New Guinea" },
	{ value: "PY", label: "Paraguay" },
	{ value: "PE", label: "Peru" },
	{ value: "PH", label: "Philippines" },
	{ value: "PN", label: "Pitcairn" },
	{ value: "PL", label: "Poland" },
	{ value: "PT", label: "Portugal" },
	{ value: "PR", label: "Puerto Rico" },
	{ value: "QA", label: "Qatar" },
	{ value: "RE", label: "R9union" },
	{ value: "RO", label: "Romania" },
	{ value: "RU", label: "Russian Federation" },
	{ value: "RW", label: "Rwanda" },
	{ value: "BL", label: "Saint Barthe9lemy" },
	{ value: "SH", label: "Saint Helena, Ascension and Tristan Da Cunha" },
	{ value: "KN", label: "Saint Kitts and Nevis" },
	{ value: "LC", label: "Saint Lucia" },
	{ value: "MF", label: "Saint Martin (French Part)" },
	{ value: "PM", label: "Saint Pierre and Miquelon" },
	{ value: "VC", label: "Saint Vincent and the Grenadines" },
	{ value: "WS", label: "Samoa" },
	{ value: "SM", label: "San Marino" },
	{ value: "ST", label: "Sao Tome and Principe" },
	{ value: "SA", label: "Saudi Arabia" },
	{ value: "SN", label: "Senegal" },
	{ value: "RS", label: "Serbia" },
	{ value: "SC", label: "Seychelles" },
	{ value: "SL", label: "Sierra Leone" },
	{ value: "SG", label: "Singapore" },
	{ value: "SX", label: "Sint Maarten (Dutch Part)" },
	{ value: "SK", label: "Slovakia" },
	{ value: "SI", label: "Slovenia" },
	{ value: "SB", label: "Solomon Islands" },
	{ value: "SO", label: "Somalia" },
	{ value: "ZA", label: "South Africa" },
	{ value: "GS", label: "South Georgia and the South Sandwich Islands" },
	{ value: "SS", label: "South Sudan" },
	{ value: "ES", label: "Spain" },
	{ value: "LK", label: "Sri Lanka" },
	{ value: "SR", label: "Suriname" },
	{ value: "SJ", label: "Svalbard and Jan Mayen" },
	{ value: "SZ", label: "Swaziland" },
	{ value: "SE", label: "Sweden" },
	{ value: "CH", label: "Switzerland" },
	{ value: "SY", label: "Syrian Arab Republic" },
	{ value: "TW", label: "Taiwan, Province of China" },
	{ value: "TJ", label: "Tajikistan" },
	{ value: "TZ", label: "Tanzania, United Republic of" },
	{ value: "TH", label: "Thailand" },
	{ value: "TL", label: "Timor-leste" },
	{ value: "TG", label: "Togo" },
	{ value: "TK", label: "Tokelau" },
	{ value: "TO", label: "Tonga" },
	{ value: "TT", label: "Trinidad and Tobago" },
	{ value: "TN", label: "Tunisia" },
	{ value: "TR", label: "Turkey" },
	{ value: "TM", label: "Turkmenistan" },
	{ value: "TC", label: "Turks and Caicos Islands" },
	{ value: "TV", label: "Tuvalu" },
	{ value: "UG", label: "Uganda" },
	{ value: "UA", label: "Ukraine" },
	{ value: "AE", label: "United Arab Emirates" },
	{ value: "GB", label: "United Kingdom" },
	{ value: "US", label: "United States" },
	{ value: "UM", label: "United States Minor Outlying Islands" },
	{ value: "UY", label: "Uruguay" },
	{ value: "UZ", label: "Uzbekistan" },
	{ value: "VU", label: "Vanuatu" },
	{ value: "VE", label: "Venezuela, Bolivarian Republic of" },
	{ value: "VN", label: "Vietnam" },
	{ value: "VG", label: "Virgin Islands, British" },
	{ value: "VI", label: "Virgin Islands, U.S." },
	{ value: "WF", label: "Wallis and Futuna" },
	{ value: "EH", label: "Western Sahara" },
	{ value: "YE", label: "Yemen" },
	{ value: "ZM", label: "Zambia" },
	{ value: "ZW", label: "Zimbabwe" },
];

export const noticePeriodList = [
	{ value: "fifteen_days", label: "15 Days" },
	{ value: "thirty_days", label: "30 Days" },
	{ value: "fortyfive_days", label: "45 Days" },
	{ value: "sixty_days", label: "60 Days" },
	{ value: "seventyfive_days", label: "75 Days" },
	{ value: "ninety_days", label: "90 Days" },
	{ value: "immediate_joiner", label: "Immediate joiner" },
	{ value: "on_notice_period", label: "On notice period" },
];

export const sourcesList = [
	{
		value: "Google",
		label: "Google",
	},
	{
		value: "LinkedIn",
		label: "LinkedIn",
	},
	{
		value: "Naukri",
		label: "Naukri",
	},
	{
		value: "Monster",
		label: "Monster",
	},
	{
		value: "Facebook",
		label: "Facebook",
	},
	{
		value: "Indeed",
		label: "Indeed",
	},
	{
		value: "Other",
		label: "Other",
	},
];

export const candidateExpections = [
	{ value: "Learning", label: "Learning" },
	{ value: "Work Life Balance", label: "Work-Life Balance" },
	{ value: "Money", label: "Money" },
	{ value: "Office Environment", label: "Office Environment" },
	{ value: "Others", label: "Others" },
];

export const skillLevels = [
	{ value: "Poor", label: "Poor" },
	{ value: "Fair", label: "Fair" },
	{ value: "Good", label: "Good" },
	{ value: "Very_Good", label: "Very Good" },
	{ value: "Excellent", label: "Excellent" },
];

export const avatarBgColor: { [key: string]: string } = {
	A: "#1ABC9C",
	a: "#1ABC9C",
	B: "#16A085",
	b: "#16A085",
	C: "#F1C40F",
	c: "#F1C40F",
	D: "#F39C12",
	d: "#F39C12",
	E: "#2ECC71",
	e: "#2ECC71",
	F: "#27AE60",
	f: "#27AE60",
	G: "#EF8D4D",
	g: "#EF8D4D",
	H: "#D35400",
	h: "#D35400",
	I: "#3498DB",
	i: "#3498DB",
	J: "#2980B9",
	j: "#2980B9",
	K: "#E74C3C",
	k: "#E74C3C",
	L: "#C0392B",
	l: "#C0392B",
	M: "#9B59B6",
	m: "#9B59B6",
	N: "#8E44AD",
	n: "#8E44AD",
	O: "#BDC3C7",
	o: "#BDC3C7",
	P: "#34495E",
	p: "#34495E",
	Q: "#34495E",
	q: "#34495E",
	R: "#95A5A6",
	r: "#95A5A6",
	S: "#7F8C8D",
	s: "#7F8C8D",
	T: "#EC87BF",
	t: "#EC87BF",
	U: "#D870AD",
	u: "#D870AD",
	V: "#F69785",
	v: "#F69785",
	W: "#9BA37E",
	w: "#9BA37E",
	X: "#C9A669",
	x: "#C9A669",
	Y: "#B49255",
	y: "#B49255",
	Z: "#A94136",
	z: "#A94136",
	default: "#A94136",
};

export const engagementType = [
	{
		value: "Full_Time",
		label: "Full-Time",
	},
	{
		value: "Part_Time",
		label: "Part-Time",
	},
	{
		value: "Contract",
		label: "Contract",
	},
];

export const days = [
	{
		id: "1",
		check: false,
		day: "Monday",
		value: "MON",
	},
	{
		id: "2",
		check: false,
		day: "Tuesday",
		value: "TUE",
	},
	{
		id: "3",
		check: false,
		day: "Wednesday",
		value: "WED",
	},
	{
		id: "4",
		check: false,
		day: "Thursday",
		value: "THU",
	},
	{
		id: "5",
		check: false,
		day: "Friday",
		value: "FRI",
	},
	{
		id: "6",
		check: false,
		day: "Saturday",
		value: "SAT",
	},
	{
		id: "7",
		check: false,
		day: "Sunday",
		value: "SUN",
	},
];

export const timeSlot = [
	{ value: "9:00", label: "9:00" },
	{ value: "9:30", label: "9:30" },
	{ value: "10:00", label: "10:00" },
	{ value: "10:30", label: "10:30" },
	{ value: "11:00", label: "11:00" },
	{ value: "11:30", label: "11:30" },
	{ value: "12:00", label: "12:00" },
	{ value: "12:30", label: "12:30" },
	{ value: "13:00", label: "13:00" },
	{ value: "13:30", label: "13:30" },
	{ value: "14:00", label: "14:00" },
	{ value: "14:30", label: "14:30" },
	{ value: "15:00", label: "15:00" },
	{ value: "15:30", label: "15:30" },
	{ value: "16:00", label: "16:00" },
	{ value: "16:30", label: "16:30" },
	{ value: "17:00", label: "17:00" },
	{ value: "17:30", label: "17:30" },
	{ value: "18:00", label: "18:00" },
	{ value: "18:30", label: "18:30" },
	{ value: "19:00", label: "19:00" },
	{ value: "19:30", label: "19:30" },
	{ value: "20:00", label: "20:00" },
	{ value: "20:30", label: "20:30" },
	{ value: "21:00", label: "21:00" },
	{ value: "21:30", label: "21:30" },
	{ value: "22:00", label: "22:00" },
	{ value: "22:30", label: "22:30" },
	{ value: "23:00", label: "23:00" },
	{ value: "23:30", label: "23:30" },
	{ value: "24:00", label: "24:00" },
];

export const departmentList = [
	"Sales",
	"Marketing",
	"Human Resources",
	"Finance",
	"Operations",
	"Information Technology (IT)",
	"Customer Service",
	"Research and Development (R&D)",
	"Administration",
	"Legal",
	"Quality Assurance (QA)",
	"Product Management",
	"Supply Chain Management",
	"Business Development",
	"Engineering",
	"Design",
	"Project Management",
	"Manufacturing",
	"Logistics",
	"Public Relations (PR)",
];

export const companyTypeOptions = [
	"Public Limited Company",
	"Private Limited Company",
	"Partnership Firm",
	"Sole Proprietorship",
	"Limited Liability Partnership (LLP)",
	"Government / Public Sector Undertaking (PSU)",
	"Non-Governmental Organization (NGO)",
	"Cooperative Society",
];

export const organisationSizes = [
	{ value: "very_small", label: "1-10 (Very Small)" },
	{ value: "small", label: "11-50 (Small)" },
	{ value: "medium", label: "51-200 (Medium)" },
	{ value: "large", label: "201-500 (Large)" },
	{ value: "very_large", label: "501-1000 (Very Large)" },
	{ value: "enterprise", label: "1000+ (Enterprise)" },
];

export const assetsList = [
	"Laptop",
	"Desktop",
	"Mobile",
	"Tablet",
	"Monitor",
	"Keyboard",
	"Mouse",
	"Headset",
	"Others",
];

export const addressTypes = ["personal", "professional"];

export const profileProgressStroke = { "0%": "#F7CF51", "100%": "#F4AA41" };

export const tenantStatusColors: {
	[key: string]: {
		customColor: string;
		labelColor: string;
	};
} = {
	Active: { customColor: "#ECFDF3", labelColor: "#027A48" },
	Pending: { customColor: "#FFFAEB", labelColor: "#B54708" },
	Onboarding: { customColor: "#EEF4FF", labelColor: "#3538CD" },
};

export const reimbursementColors: {
	[key: string]: {
		customColor: string;
		labelColor: string;
	};
} = {
	APPROVED: {
		customColor: "#ECFDF3",
		labelColor: "#027A48",
	},
	PENDING: {
		customColor: "#FFFAEB",
		labelColor: "#B54708",
	},
	REJECTED: {
		customColor: "#fdf2f2",
		labelColor: "#EB5757",
	},
};
export const statusColors: {
	[key: string]: {
		customColor: string;
		labelColor: string;
	};
} = {
	Approved: {
		customColor: "#ECFDF3",
		labelColor: "#027A48",
	},
	Pending: {
		customColor: "#FFFAEB",
		labelColor: "#B54708",
	},
	Rejected: {
		customColor: "#fdf2f2",
		labelColor: "#EB5757",
	},
};
export const exitColors: {
	[key: string]: {
		customColor: string;
		labelColor: string;
	};
} = {
	PreClearance: {
		customColor: "#F4F3FF",
		labelColor: "#5925DC",
	},
	Clearance: {
		customColor: "#FFFAEB",
		labelColor: "#B54708",
	},
	InExit: {
		customColor: "#fdf2f2",
		labelColor: "#EB5757",
	},
	Exited: {
		customColor: "#ECFDF3",
		labelColor: "#027A48",
	},
};

export const assetStatus: {
	[key: string]: {
		customColor: string;
		labelColor: string;
	};
} = {
	Assigned: {
		customColor: "#ECFDF3",
		labelColor: "#027A48",
	},
	Not_Assigned: {
		customColor: "#fdf2f2",
		labelColor: "#EB5757",
	},
};

export const onboardingFormFields = ["Full Time", "Other"];

export const badgeColors = {
	ready: {
		background: "#ECFDF3",
		text: "#027A48",
	},
	pending: {
		background: "#FFFAEB",
		text: "#B54708",
	},
	rejected: {
		background: "#fdf2f2",
		text: "#EB5757",
	},
};

export const statusOptions = [
	{ label: "Approved", value: "Approved" },
	{ label: "Pending", value: "Pending" },
	{ label: "Rejected", value: "Rejected" },
	{ label: "Cancelled", value: "Cancelled" },
];

export const badgeColorMap = {
	PENDING: {
		color: badgeColors.pending.background,
		text: badgeColors.pending.text,
	},
	PROCESSED: {
		color: badgeColors.ready.background,
		text: badgeColors.ready.text,
	},
	FAILED: {
		color: badgeColors.rejected.background,
		text: badgeColors.rejected.text,
	},
};

export const analyticsColors = [
	"#FFBDF8",
	"#6BDBFF",
	"#FFDB56",
	"#8CC9BA",
	"#C98BFA",
	"#C5D7BF",
	"#FC9090",
	"#CCBDFF",
];

export const genderMap: Record<
	string,
	{
		label: string;
		value: string;
		key: string;
		pronoun: string;
	}
> = {
	male: {
		label: "Male",
		value: "Male",
		key: "Male",
		pronoun: "He",
	},
	female: {
		label: "Female",
		value: "Female",
		key: "Female",
		pronoun: "She",
	},
	other: {
		label: "Other",
		value: "Other",
		key: "Other",
		pronoun: "They",
	},
	nonBinary: {
		label: "Non Binary",
		value: "Non Binary",
		key: "Non_Binary",
		pronoun: "They",
	},
	preferNotToSay: {
		label: "Prefer not to say",
		value: "Prefer not to say",
		key: "Prefer_Not_To_Say",
		pronoun: "They",
	},
};

export const workModeMap: Record<
	string,
	{
		label: string;
		value: string;
		key: string;
	}
> = {
	hybrid: {
		label: "Hybrid",
		value: "Hybrid",
		key: "Hybrid",
	},
	inOffice: {
		label: "In Office",
		value: "In Office",
		key: "InOffice",
	},
	remote: {
		label: "Remote",
		value: "Remote",
		key: "Remote",
	},
};
export const payScheduleoptions = [
	{ label: "Every Week", value: "W" },
	{ label: "Every Two Weeks", value: "BW" },
	{ label: "Twice a Month", value: "TM" },
	{ label: "Once a Month", value: "OM" },
];

export const daynumber = [
	{ label: "1st", value: 1 },
	{ label: "2nd", value: 2 },
	{ label: "3rd", value: 3 },
	{ label: "4th", value: 4 },
	{ label: "5th", value: 5 },
	{ label: "6th", value: 6 },
	{ label: "7th", value: 7 },
	{ label: "8th", value: 8 },
	{ label: "9th", value: 9 },
	{ label: "10th", value: 10 },
	{ label: "11th", value: 11 },
	{ label: "12th", value: 12 },
	{ label: "13th", value: 13 },
	{ label: "14th", value: 14 },
	{ label: "15th", value: 15 },
	{ label: "16th", value: 16 },
	{ label: "17th", value: 17 },
	{ label: "18th", value: 18 },
	{ label: "19th", value: 19 },
	{ label: "20th", value: 20 },
	{ label: "21st", value: 21 },
	{ label: "22nd", value: 22 },
	{ label: "23rd", value: 23 },
	{ label: "24th", value: 24 },
	{ label: "25th", value: 25 },
	{ label: "26th", value: 26 },
	{ label: "27th", value: 27 },
	{ label: "28th", value: 28 },
	{ label: "29th", value: 29 },
	{ label: "30th", value: 30 },
	{ label: "31st", value: 31 },
];

export const assetStatusOptions = [
	{ label: "Assigned", value: "Assigned" },
	{ label: "Not Assigned", value: "Not_Assigned" },
];

export const contributionRateOptions = {
	RESTRICTED_WAGE: {
		label: "RESTRICTED WAGE",
		value: "RESTRICTED_WAGE",
	},
	ACTUAL_WAGE: {
		label: "ACTUAL WAGE",
		value: "ACTUAL_WAGE",
	},
};

export const squadThemedList = [
	{ label: "Technology", value: "technology" },
	{ label: "Nature", value: "nature" },
	{ label: "Space", value: "space" },
	{ label: "Mythology", value: "mythology" },
	{ label: "Music", value: "music" },
	{ label: "Adventure", value: "adventure" },
	{ label: "Fantasy", value: "fantasy" },
];

export const squadScoreRefreshInterval = [
	{ label: "1 Month", value: "ONE_MONTH" },
	{ label: "3 Months", value: "THREE_MONTHS" },
	{ label: "6 Months", value: "SIX_MONTHS" },
	{ label: "12 Months", value: "TWELVE_MONTHS" },
];

export const appointmentDurationOptions = [
	{ value: 15, label: "15 minutes" },
	{ value: 30, label: "30 minutes" },
	{ value: 60, label: "1 hour" },
];

export const reimbursementStatusOptions = [
	{ label: "PROCESSED", value: "PROCESSED" },
	{ label: "APPROVED", value: "APPROVED" },
	{ label: "PENDING", value: "PENDING" },
	{ label: "REJECTED", value: "REJECTED" },
];

export const maritalStatus = [
	{ value: "single", label: "Single" },
	{ value: "married", label: "Married" },
	{ value: "divorced", label: "Divorced" },
	{ value: "widowed", label: "Widowed" },
];

export const teamMemberStatusColors: {
	[key: string]: {
		background: string;
		text: string;
	};
} = {
	PreInvited: {
		background: "#ECFDF3",
		text: "#027A48",
	},
	Invited: {
		background: "#D9E9FF",
		text: "#2E2BB2",
	},
	onboarding: {
		background: "#F1E0FF",
		text: "#7209DC",
	},

	PendingActivation: {
		background: "#FFF3D0",
		text: "#833C00",
	},
	onboarded: {
		background: "#ECFDF3",
		text: "#0B733F",
	},

	PreClearance: {
		background: "#ffeed3",
		text: "#A82400",
	},

	Clearance: {
		background: "#ffeed3",
		text: "#A82400",
	},

	InExit: {
		background: "#ffeed3",
		text: "#A82400",
	},

	Exited: {
		background: "#f5f5f5",
		text: " #595959",
	},
	Removed: {
		background: "#fdf2f2",
		text: "#EB5757",
	},
	default: {
		background: "#F7CF51",
		text: "#262626",
	},
};

export const relationshipOptions = [
	{ value: "Mother", label: "Mother" },
	{ value: "Father", label: "Father" },
	{ value: "Husband", label: "Husband" },
	{ value: "Wife", label: "Wife" },
	{ value: "Son", label: "Son" },
	{ value: "Daughter", label: "Daughter" },
	{ value: "Brother", label: "Brother" },
	{ value: "Sister", label: "Sister" },
	{ value: "Guardian", label: "Guardian" },
	{ value: "Friend", label: "Friend" },
	{ value: "Colleague", label: "Colleague" },
	{ value: "Other", label: "Other" },
	{ value: "Mother", label: "Mother" },
	{ value: "Father", label: "Father" },
	{ value: "Husband", label: "Husband" },
	{ value: "Wife", label: "Wife" },
	{ value: "Son", label: "Son" },
	{ value: "Daughter", label: "Daughter" },
	{ value: "Brother", label: "Brother" },
	{ value: "Sister", label: "Sister" },
	{ value: "Guardian", label: "Guardian" },
	{ value: "Friend", label: "Friend" },
	{ value: "Colleague", label: "Colleague" },
	{ value: "Other", label: "Other" },
];

export const halfDayOptions = [
	{
		value:"first_half",
		label: "First Half (Morning)",
	},
	{
		value:"second_half",
		label: "Second Half (Afternoon)",
	},
];
